<template>
  <div style="display: inline-block;width: 100%;">
    <div class="select_user_box">
      <a-input-search
        class="select_search"
        id="select_user_search"
        readOnly
        :placeholder="placeholder"
        @search="onSearch()"
      >
        <a-button
          class="add_btn"
          type="primary"
          slot="enterButton"
          icon="plus"
          title="添加"
        />
      </a-input-search>
      <a-button
        id="clear_btn"
        type="danger"
        icon="delete"
        title="清空"
        @click="clearSelUsers"
      />
      <ul id="select_user_list">
        <li v-for="item in selUsers" :key="item.userName">
          <span>{{ item.realName }}</span>
          <a-icon type="close" @click="delUser(item.userName)" />
        </li>
      </ul>
    </div>
    <a-modal
      title="选择用户"
      v-model="selectuseModal.visible"
      width="70%"
      :dialog-style="{ top: '20px' }"
      :footer="false"
      destroyOnClose
      :maskClosable="false"
    >
      <selectuser @callback="selectUserCallback" :multi="true" />
    </a-modal>
  </div>
</template>

<script>
import selectuser from "./searchuser.vue";
export default {
  name: "MultiSearchUser",
  props: {
    checkUsers: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      selUsers: [],
      placeholder: "请选择",
      defaultUlHeight: 0,
      defaultInputHeight: 0,
      selectuseModal: {
        visible: false,
      },
    };
  },
  components: {
    selectuser,
  },
  mounted() {
    this.selUsers = [...this.checkUsers];
    this.$nextTick(() => {
      this.defaultInputHeight =
        document.getElementById("select_user_search").offsetHeight;
    });
  },
  watch: {
    checkUsers(val) {
      this.selUsers = [...val];
    },
  },
  methods: {
    onSearch() {
      this.selectuseModal.visible = true;
    },
    selectUserCallback(users) {
      users.forEach((item) => {
        if (!this.selUsers.some((u) => u.userName === item.userName)) {
          this.selUsers.push(item);
        }
      });
      this.changeInputHeight();
      this.$emit("callback", this.selUsers);
      this.selectuseModal.visible = false;
    },
    delUser(userName) {
      this.selUsers = this.selUsers.filter(
        (item) => item.userName !== userName
      );
      this.changeInputHeight();
      this.$emit("callback", this.selUsers);
    },
    clearSelUsers() {
      let _this = this;
      _this.$confirm({
        title: "是否清空已选用户？",
        onOk() {
          _this.selUsers = [];
          _this.changeInputHeight();
          _this.$emit("callback", _this.selUsers);
        },
      });
    },
    changeInputHeight() {
      this.$nextTick(() => {
        let listDom = document.getElementById("select_user_list");
        let firstLi = listDom.firstChild;
        if (this.defaultUlHeight == 0 && firstLi) {
          this.defaultUlHeight = firstLi.offsetHeight;
        }
        let currUlheight = listDom.offsetHeight;
        let inputDom = document.getElementById("select_user_search");
        let clearBtnDom = document.getElementById("clear_btn");
        if (currUlheight > this.defaultUlHeight) {
          let h =
            this.defaultInputHeight *
              Math.floor(currUlheight / this.defaultUlHeight) +
            "px";
          inputDom.style.height = h;
          inputDom.nextSibling.firstChild.style.height = h;
          clearBtnDom.style.height = h;
        } else {
          let h = this.defaultInputHeight + "px";
          inputDom.style.height = h;
          inputDom.nextSibling.firstChild.style.height = h;
          clearBtnDom.style.height = h;
        }
        if (this.selUsers.length === 0) {
          this.placeholder = "请选择";
        } else {
          this.placeholder = "";
        }
      });
    },
  },
};
</script>

<style>
.select_user_box {
  position: relative;
  line-height: 32px;
  width: 100%;
  text-align: left;
}
.select_user_box .select_search {
  width: 94%;
}
.select_user_box .select_search input {
  padding-top: 0;
  padding-bottom: 0;
}
.select_user_box .select_search .add_btn {
  border-radius: 0;
}
.select_user_box #clear_btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  position: relative;
  top: 2px;
  border: none;
}
.select_user_box ul {
  position: absolute;
  top: 3px;
  left: 5px;
  z-index: 100;
  width: 88%;
}
.select_user_box li {
  position: relative;
  float: left;
  font-size: 12px;
  height: 24px;
  margin-top: 3px;
  line-height: 22px;
  max-width: 99%;
  margin-right: 4px;
  padding: 0 20px 0 10px;
  overflow: hidden;
  background-color: #fafafa;
  border: 1px solid #e8e8e8;
  border-radius: 2px;
  cursor: default;
}
.select_user_box li i {
  position: absolute;
  font-size: 10px;
  top: 6px;
  right: 5px;
  cursor: pointer;
}
</style>